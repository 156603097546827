import React from "react";
import "./Team.css";

import TeamCard from "./TeamCard";

const TeamMembers = [
  {
    name: "Er. Alok Kumar",
    pic: "https://firebasestorage.googleapis.com/v0/b/voe-academy.appspot.com/o/team%2FAlok.jpg?alt=media&token=3bf6cdbd-e45b-4bb2-afee-7ed4f0ebd144",
    about: {
      experience: "3.5 years",
      designation: "Founder & Director",
      qualification:
        "Ex-GURUQ, Ex-Head (Adult Literacy Project RCCH 3080), PCB Design Engineer (Electrowaves Electronics), B.E (Electrical & Electronics Engineering), Panjab University",
    },
  },
  {
    name: "Anjali Birhman",
    pic: "https://firebasestorage.googleapis.com/v0/b/voe-academy.appspot.com/o/team%2Fanjali.jpeg?alt=media&token=23ee9d67-d993-4892-b7d1-5775d7a01372",
    about: {
      experience: "3.5 years",
      designation: "Co-Founder",
      qualification: "BSc Radiology",
    },
  },

  {
    name: "Dinesh Singh",
    pic: "https://firebasestorage.googleapis.com/v0/b/voe-academy.appspot.com/o/team%2Fdinesh.jpg?alt=media&token=ef4727d7-8f7e-42a6-9d53-ac17b3522794",
    about: {
      experience: "1 year",
      designation: "Chief Operating Officer (COO)",
      qualification: "IIT Patna, M.Tech (VLSI)",
    },
  },

  {
    name: "Deepak Kumar",
    pic: "https://firebasestorage.googleapis.com/v0/b/voe-academy.appspot.com/o/team%2Fdeepak.jpg?alt=media&token=cfe642d9-41a7-44b7-b276-fa8416f2592a",
    about: {
      experience: "3.5 years",
      designation: "Regional Head, Buxar (Bihar)",
      qualification: "B.Sc (Chemistry), VKSU, Ara, CGL: Cleared Prelims",
    },
  },
  {
    name: "Priyanshu Jindal",
    pic: "https://firebasestorage.googleapis.com/v0/b/voe-academy.appspot.com/o/team%2Fpriyanshu.jpeg?alt=media&token=f414ff0b-fd72-4816-a4e4-4d7093c8065a",
    about: {
      experience: "2 years",
      designation: "Content Head",
      qualification: "B.Com",
    },
  },
  {
    name: "Er. Samar Bhadur Verma",
    pic: "https://firebasestorage.googleapis.com/v0/b/voe-academy.appspot.com/o/team%2Fsamar.jpg?alt=media&token=0694ecf4-505e-474e-a5de-29a0bbc5f0b2",
    about: {
      experience: "3.5 years",
      designation: "Head, Subject Specialist",
      qualification:
        "B.Tech (ECE), Dr. A.P.J. Abdul Kalam Technical University, Lucknow (UP), JEE Mains: Qualified",
    },
  },
  {
    name: "Simran Gulati",
    pic: "https://firebasestorage.googleapis.com/v0/b/voe-academy.appspot.com/o/team%2Fsimran.jpeg?alt=media&token=b1006851-de99-4abe-8c5f-6aeb62669e39",
    about: {
      experience: "3 years",
      designation: "Growth & Strategy Head",
      qualification:
        "B.Com (Management Hons.) - GCCBA, Panjab University, Chandigarh (2018-2021), PGDM (Finance) - IFIM JAGSoM Bangalore (2022-2024) (Pursuing)",
    },
  },
  {
    name: "Nainy",
    pic: "https://firebasestorage.googleapis.com/v0/b/voe-academy.appspot.com/o/team%2Fnainy.jpeg?alt=media&token=a4ba70de-bb59-4435-bd6b-91a37ea9007c",
    about: {
      experience: "5 years",
      designation: "Physics Specialist",
      qualification: "M.Sc (Physics), GATE & NET qualified",
    },
  },
  {
    name: "Siddharth Singh",
    pic: "https://firebasestorage.googleapis.com/v0/b/voe-academy.appspot.com/o/team%2Fsiddhart.jpg?alt=media&token=d8d67813-f709-4699-a009-3d07f8dc5969",
    about: {
      experience: "4 years",
      designation: "Mathematics Specialist",
      qualification: "B.Tech (IT)",
    },
  },
  {
    name: "Eva Bansal",
    pic: "https://firebasestorage.googleapis.com/v0/b/voe-academy.appspot.com/o/team%2Feva.jpeg?alt=media&token=ca9c4f4c-cdea-49f0-9de4-3c6539ec4f3f",
    about: {
      experience: "2 years",
      designation: "Tutor",
      qualification:
        "Certified Basic & Advanced English Trainer, Ex-Academic Counsellor, Hit Bullseye - Mohali, B.O.D. Member (RCCH 3080), M.A. in Psychology, Panjab University",
    },
  },

  {
    name: "Charvi Pareek",
    pic: "https://firebasestorage.googleapis.com/v0/b/voe-academy.appspot.com/o/team%2Fcharvi.jpeg?alt=media&token=6a88c54a-3bf7-4688-8d0b-9223950f6ef2",
    about: {
      experience: "3 years",
      designation: "Tutor",
      qualification:
        "Ex-GuruQ, Head: Adult Literacy Project (RCCH 3080, Chandigarh)",
    },
  },
  {
    name: "Anica",
    pic: "https://firebasestorage.googleapis.com/v0/b/voe-academy.appspot.com/o/team%2FAnica.jpg?alt=media&token=8059518d-f060-4721-9872-6bd31424e2c8",
    about: {
      experience: "3 years",
      designation: "Tutor",
      qualification:
        "Graduation - BCA, Post-Graduation - MCA, M.A. in English, Certified Tarot Reader",
    },
  },
  {
    name: "Ravi Dev Yadav",
    pic: "https://firebasestorage.googleapis.com/v0/b/voe-academy.appspot.com/o/team%2Fravi.jpeg?alt=media&token=cd019ca6-cb35-4e9b-b830-e259d38e698d",
    about: {
      experience: "2.5 years",
      designation: "Tutor",
      qualification: "IIT JAM: All India Rank 86, M.Sc. Chemistry (IIT Mumbai)",
    },
  },
  {
    name: "Kaustubh Verma",
    pic: "https://firebasestorage.googleapis.com/v0/b/voe-academy.appspot.com/o/team%2Fkaustubh.jpeg?alt=media&token=2166b021-8df1-4bfc-a16a-8775cce73873",
    about: {
      experience: "4 years",
      designation: "Tutor",
      qualification: "JEE Mains: Qualified, M.Sc. Physics, B.Tech (ECE), AKTU",
    },
  },
  {
    name: "Khushi Singh",
    pic: "https://firebasestorage.googleapis.com/v0/b/voe-academy.appspot.com/o/team%2Fkhushi%20Singh.jpeg?alt=media&token=d0ef0fdf-b59c-4eed-a58b-78d6d7c09b22",
    about: {
      experience: "1+ Years",
      designation: "Social media + Content Research manager",
      qualification: "B. Com",
    },
  },
  {
    name: "Ayushi Yadav",
    pic: "https://firebasestorage.googleapis.com/v0/b/voe-academy.appspot.com/o/team%2FAyushi%20Yadav.jpeg?alt=media&token=6d43f8c3-5f26-432f-8348-5c2a39d70abb",
    about: {
      experience: "1+ Years in Social media",
      designation: "Social Media Manager",
      qualification: "Bsc(Biology)",
    },
  },
];

const Team = () => {
  // const [TeamMembers, setTeamMembers] = useState([]);
  // const handleGetTeachersData = async () => {
  //   let headers = new Headers();
  //   headers.append("Access-Control-Allow-Origin", "http://localhost:3000");
  //   headers.append("Access-Control-Allow-Credentials", "true");
  //   headers.append("Content-Type", "application/json");
  //   headers.append("Accept", "application/json");
  //   // REQUEST SIGNUP
  //   await fetch("http://localhost:4000/getteachersdata", {
  //     method: "get",
  //     headers: headers,
  //     credentials: "include",
  //   })
  //     .then((res) => {
  //       return res.json();
  //     })
  //     .then((data) => {
  //       console.log(data);
  //       setTeamMembers(data.teachers);
  //     });
  // };
  // useEffect(() => {
  //   handleGetTeachersData();
  // }, []);
  return (
    <div className="TeamPage">
      <h1 id="TeamHeading">MEET OUR TEAM</h1>
      <div className="artboard">
        {TeamMembers.map((Member, id) => {
          return (
            <TeamCard
              id={id}
              pic={Member.pic}
              name={Member.name}
              about={Member.about}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Team;
