import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ReactDOM from "react-dom/client";
import "./index.css";
import Home from "./Home/Home";
import reportWebVitals from "./reportWebVitals";
import Navbar from "./components/Navbar/Navbar";
import Signup from "./Auth/Signup/Signup";
import Footer from "./components/Footer/Footer";
import BookSession from "./BookSession/BookSession";
import About from "./About/About";
import Query from "./Query/Query";
import QuerySolution from "./QuerySolution/QuerySolution";
import HomeTutionTricity from "./HomeTution/HomeTutionTricity";
import PaymentConfirmation from "./PaymentTestPage/PaymentConfirmation";
import Team from "./Team Profile/Team";
import Gallery from "./gallery/Gallery";
import StudyMaterial from "./Studymaterial/StudyMaterial";
import Faq from "./Faq/Faq";
import ToastComponent from "./components/Toast/Toast";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";
import Services from "./Services/Services";
import PaymentQR from "./components/PayementQR/PaymentQR";
import ScrollToTop from "./components/ScrooltoTop/Scroll";
import Carrer from "./Carrer/Carrer";
import Contact from "./Contact/Contact";
import Blogs from "./Blogs/Blogs";
import CHSBHUEntranceExam from "./Blogs/blogs/CHSBHUEntranceExam";
import Sanik from "./Blogs/blogs/SanikSchool";
import Jnvst from "./Blogs/blogs/JNVST";
import RegisterAsTutor from "./RegisterAsTutor/RegisterAsTutor";
import MDM from "./Blogs/blogs/Mid-Day Meal Scheme/MDM";
import NSEC from "./Blogs/blogs/National Secondary Education Campaign/NSEC";
import SSA from "./Blogs/blogs/Sarva Shiksha Abhiyan/SSA";
import NEP2020 from "./Blogs/blogs/National Education Policy 2020/NEP2020";
import ELFS from "./Blogs/blogs/Education Loans for students/ELFS";
import LRI2023 from "./Blogs/blogs/ Literacy Rates in India 2023/LRI2023";
import PMEGP from "./Blogs/blogs/Prime Minister Employment Creation Scheme/PMEGP";
import Books from "./Books/Books";
import BookDiscription from "./Books/BookDiscription";
import StudyMaterialDocument from "./Studymaterial/StudyMaterialDocument/StudyMaterialDocument";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <div>
        <div>
          <Navbar />
        </div>
        <div id="Main">
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/booksession" element={<BookSession />} />
            <Route path="/QuerySolution/:id" element={<QuerySolution />} />
            <Route
              path="/home-tution-tricity"
              element={<HomeTutionTricity />}
            />
            <Route path="/services" element={<Services />} />

            <Route path="/query" element={<Query />} />
            <Route path="/blogs" element={<Blogs />} />

            <Route path="/studymaterial" element={<StudyMaterial />} />
            <Route
              path="/studymaterial/document/:document_id"
              element={<StudyMaterialDocument />}
            />
            <Route path="/faq" element={<Faq />} />

            <Route path="/about" element={<About />} />
            <Route path="/team" element={<Team />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            {/* UTILS */}
            <Route path="/toast" element={<ToastComponent />} />
            <Route
              path="/coursepaymentconfirmation/:id"
              element={<PaymentConfirmation />}
            />
            <Route path="/paymentqr" element={<PaymentQR />} />
            <Route path="/career" element={<Carrer />} />
            <Route path="/register-as-tutor" element={<RegisterAsTutor />} />
            <Route path="/contactus" element={<Contact />} />
            <Route path="/books" element={<Books />} />
            <Route path="/books/:book_id" element={<BookDiscription />} />

            {/* <-----------------BLOGS--------------------> */}
            <>
              {" "}
              <Route
                path="/blogs/chs-bhu-entrance-exam-2024"
                element={<CHSBHUEntranceExam />}
              />
              <Route
                path="/blogs/sanik-school-entrance-exam-2024"
                element={<Sanik />}
              />
              <Route path="/blogs/jnvst-2024" element={<Jnvst />} />
              <Route
                path="/blogs/Mid-Day-Meal-Scheme-Improving-Nutrition-and-Education"
                element={<MDM />}
              />
              <Route
                path="/blogs/Rashtriya-Madhyamik-Shiksha-Abhiyan"
                element={<NSEC />}
              />
              <Route path="/blogs/Sarva-Shiksha-Abhiyan" element={<SSA />} />
              <Route
                path="/blogs/National-Education-Policy-2020"
                element={<NEP2020 />}
              />
              <Route
                path="/blogs/Comprehensive-Guide-to-Education-Loans-in-India"
                element={<ELFS />}
              />
              <Route
                path="/blogs/Literacy-Rates-in-India-2023"
                element={<LRI2023 />}
              />
              <Route
                path="/blogs/employment-generation-pmegp"
                element={<PMEGP />}
              />
            </>
          </Routes>
        </div>
        <Footer />
      </div>
    </BrowserRouter>
  </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))se

// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
