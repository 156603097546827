import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./StudyMaterialDocument.css";

const StudyMaterialDocument = () => {
  let params = useParams();

  const [document_url, setDocumentUrl] = useState("");
  const [isDocumentPdf, setIsDocumentPdf] = useState(false);
  const getDocument = async () => {
    try {
      // if (!signinbool) {
      //   setShowSignup(true);
      // } else {
      let headers = new Headers();
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("Content-Type", "application/json");

      headers.append("Accept", "application/json");
      // REQUEST SIGNUP

      await fetch(
        process.env.REACT_APP_BACKEND_URL +
          `/get-study-material-doc/${params.document_id}`,
        {
          method: "get",
          headers: headers,
          mode: "cors",
          credentials: "include",
        }
      )
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setDocumentUrl(data.data.url);
          const str = data.data.filename;
          if (str.substring(str.length - 3, str.length) === "pdf") {
            setIsDocumentPdf(true);
          }
        });
      // }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // Disable right-click
    const disableRightClick = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", disableRightClick);

    const embedElement = document.getElementById("StudyMaterialDocumentIframe");
    if (embedElement) {
      embedElement.addEventListener("contextmenu", disableRightClick);
    }

    // Detect F12 or Ctrl+Shift+I key press
    const detectKeyPress = (e) => {
      if (e.key === "F12" || (e.ctrlKey && e.shiftKey && e.key === "I")) {
        alert("Developer tools are disabled!");
        e.preventDefault();
      }
    };
    document.addEventListener("keydown", detectKeyPress);

    // Warn when DevTools are open (by detecting window resize)
    const detectDevTools = () => {
      const widthThreshold = 200;
      const heightThreshold = 100;

      if (
        window.innerWidth < widthThreshold ||
        window.innerHeight < heightThreshold
      ) {
        alert("Please do not use the inspect tools!");
      }
    };

    const devToolsCheckInterval = setInterval(detectDevTools, 1000);

    // Clean up on component unmount
    return () => {
      document.removeEventListener("contextmenu", disableRightClick);
      document.removeEventListener("keydown", detectKeyPress);
      clearInterval(devToolsCheckInterval);
    };
  }, []);
  useEffect(() => {
    getDocument();
  }, []);
  const downloadDocument = async (document_id) => {
    try {
      // if (!signinbool) {
      //   setShowSignup(true);
      // } else {
      let headers = new Headers();
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("Content-Type", "application/json");

      headers.append("Accept", "application/json");
      // REQUEST SIGNUP

      await fetch(
        process.env.REACT_APP_BACKEND_URL +
          `/get-study-material-doc/${document_id}`,
        {
          method: "get",
          headers: headers,
          mode: "cors",
          credentials: "include",
        }
      )
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          const a = document.createElement("a");

          a.href = data.data.url;
          a.download = data.data.filename;

          // Append the anchor to the body
          document.body.appendChild(a);

          // Trigger the click event
          a.click();

          // Remove the anchor from the body
          document.body.removeChild(a);
        });
      // }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <div>
        {isDocumentPdf ? (
          <embed
            src={`${document_url}#toolbar=0`}
            frameborder="0"
            id="StudyMaterialDocumentIframe"
            height="2000"
            width="100%"
          ></embed>
        ) : (
          <div id="StudyMaterialDocumentImage">
            <img src={`${document_url}`} alt="" srcset="" />
          </div>
        )}
      </div>
    </div>
  );
};

export default StudyMaterialDocument;
