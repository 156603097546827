import React, { useEffect, useState } from "react";
import "./StudyMaterial.css";
import PDFViewer from "../components/PdfViewer/PdfViewer";
import PDFViewer2 from "../components/PdfViewer/PdfViewer2";
import Signup from "../Auth/Signup/Signup";

const study_material_document = [
  {
    folder_id: "fol_ubs",
    title: "UP Board syllabus",
    isSubFolderExist: false,
    documents: [
      {
        title: "Class-10th English.pdf",
        document_id: "uhbYJFxkC0lzEPBotBWe",
      },
      {
        title: "Class-10th Hindi.pdf",
        document_id: "uIMbV7R7HfYxzoW6vgwS",
      },
      {
        title: "Class-10th Science.pdf",
        document_id: "tl8lqUk3mYYA8b6I2XF5",
      },
      {
        title: "Class-10th sst.pdf",
        document_id: "kzrBDbcjzLsGSIpKz98f",
      },
      {
        title: "Class-11th Biology.pdf",
        document_id: "H2kq22bsNpNBFeico60g",
      },
      {
        title: "Class-11th Chemistry.pdf",
        document_id: "jFmC7MAKasxlGqMxhw3G",
      },
      {
        title: "Class-11th English.pdf",
        document_id: "cAhMcDi72QOabDP8H3iX",
      },
      {
        title: "Class-11th Hindi.pdf",
        document_id: "9mimWT4Wd3XXgd2bQrK3",
      },
      {
        title: "Class-11th Math.pdf",
        document_id: "pDjjygx5n9B5FB30Vf0v",
      },
      {
        title: "Class-11th Physics.pdf",
        document_id: "Yx4pbZN28pIaMun2GRPM",
      },
      {
        title: "Class_12th Biology.pdf",
        document_id: "qyDCC8pqQyoUu5moWVlq",
      },
      {
        title: "Class_12th Chemistry.pdf",
        document_id: "YelbOTWtHcB73z6uqoZu",
      },
      {
        title: "Class_12th English.pdf",
        document_id: "S58huZFRRZwhK4fDVGZT",
      },
      {
        title: "Class_12th Hindi.pdf",
        document_id: "cInw6RaSFojYIoiaX13T",
      },
      {
        title: "Class_12th Math.pdf",
        document_id: "ZE0ZLTuxguEYGoC5t4hN",
      },
      {
        title: "Class_12th Physics.pdf",
        document_id: "aaAtSU7gZ6sJZtMba0ju",
      },
      {
        title: "English class 9.pdf",
        document_id: "ppEbJMkD7x9s0MImFCZ9",
      },
      {
        title: "hindi class 9.pdf",
        document_id: "XOkRM8lCvMU6hpC1tiI5",
      },
      {
        title: "maths class 9.pdf",
        document_id: "hryfECMDZyofqyhhvv5B",
      },
      {
        title: "science class 9.pdf",
        document_id: "S3pRz0B7XvsIIBYKr6o9",
      },
      {
        title: "sst class 9.pdf",
        document_id: "MsmpoEhN4fq5SqV0slHv",
      },
    ],
  },
  {
    folder_id: "fol_ref",
    title: "Reference",
    isSubFolderExist: false,
    documents: [
      {
        title:
          "Set of 15 Half Yearly Sample Papers for Class 10 Mathematics with Solutions.pdf",
        document_id: "AsP76JC8AA2PhrdKkcwt",
      },
    ],
  },
  {
    folder_id: "fol_pcb",
    title: "PCB design",
    isSubFolderExist: false,
    documents: [
      {
        title: "Circuit Symbols.pdf",
        document_id: "fBBFDMcHBGzNhepYvrT9",
      },
      {
        title: "Document from Ayushi Yadav.pdf",
        document_id: "pT8MHCJK16MhmkxsgnhB",
      },
      {
        title: "High-Speed PCB Design Guide_January 2023(1).pdf",
        document_id: "mWb2mh9KyHHhZ4XDidzZ",
      },
      {
        title: "High-Speed PCB Design Guide_January 2023.pdf",
        document_id: "WBvo4sUj1I6isCxmkteu",
      },
      {
        title: "IMG-20240920-WA0015.jpg",
        document_id: "nmYJEVBMU1UqQEMlR66i",
      },
      {
        title: "IMG-20240920-WA0016.jpg",
        document_id: "x8T3T8fEfPrxAlKJN1ac",
      },
      {
        title: "IMG-20250113-WA0002.jpg",
        document_id: "obfYrRtDkiG6JyViphIi",
      },
      {
        title: "IMG-20250113-WA0003.jpg",
        document_id: "9mngGoXWKAlHeMhXPvzT",
      },
      {
        title: "IPC standards for PCB design.pdf",
        document_id: "yfNBTAiato3jcnBW9gGc",
      },
      {
        title: "Lecture 15_20250102_130958_0000.pdf",
        document_id: "fXjuIg0YaOXZyH0TO7xN",
      },
      {
        title: "Lecture 16_20250102_131013_0000.pdf",
        document_id: "MxvXPmhBj1460tPbbLvU",
      },
      {
        title: "Lecture 17_20250102_131611_0000.pdf",
        document_id: "aY3Mp7L5Hlgb941LW44g",
      },
      {
        title: "Lecture 22 part-2 _20250103_130046_0000.pdf",
        document_id: "OadeUHCYV4n2pNueKB3s",
      },
      {
        title: "Lecture 24_20250103_130536_0000.pdf",
        document_id: "8Numuajk6LP18KtK40Kp",
      },
      {
        title: "Lecture 25_20250103_130627_0000.pdf",
        document_id: "S6mbRhjjr9ZqAu2pQIe1",
      },
      {
        title: "Lecture 26_20250103_130732_0000.pdf",
        document_id: "17a0t3KOmH5RZVHfF1oh",
      },
      {
        title: "Lecture 27_20250103_130804_0000.pdf",
        document_id: "nRV3mVr8BRHq5tIZt2Qo",
      },
      {
        title: "Lecture 4_20241207_000044_0000.pdf",
        document_id: "geEdhQMIeG17Zl2RopV5",
      },
      {
        title: "Lecture 5_20241206_231101_0000.pdf",
        document_id: "gKurHTD1JQvkmOzraH1P",
      },
      {
        title: "Lecture 6_20241206_230557_0000.pdf",
        document_id: "EUGAmcl00p5n5gMCHYvU",
      },
      {
        title: "Lecture 7_20241206_230243_0000.pdf",
        document_id: "EGkBLvlPrfhXo490kEEP",
      },
      {
        title: "Lecture 8_20241206_223412_0000.pdf",
        document_id: "RJcOLhvW8V4NtPIQleaF",
      },
      {
        title: "Lecture 9_20241225_141039_0000.pdf",
        document_id: "P1c0OIGxWWqOXIdNwEYB",
      },
      {
        title: "Midterm_Report _.pdf",
        document_id: "jcqxtFXKQvluLogoLCA8",
      },
      {
        title: "PCB Lecture 2_20241207_083750_0000.pdf",
        document_id: "3t3uQjZi5H4QYtGBZAxm",
      },
      {
        title: "PCB Lecture 3_20241207_084132_0000.pdf",
        document_id: "p7hXrN0iwGKQzCKXT32q",
      },
      {
        title: "PCB design job .pdf",
        document_id: "g5OL1jRjqiEOWuhs80oI",
      },
      {
        title: "PCB lecture 1_20241207_082551_0000.pdf",
        document_id: "MTYgaXpOe34WFb99laV9",
      },
      {
        title: "Pcb design _20241207_000303_0000.pdf",
        document_id: "4NnDFT6aM7V7oJrVzK5g",
      },
      {
        title: "Software guide.pdf",
        document_id: "CPjNhbcF7Rl5cP4qCgzU",
      },
      {
        title: "lecture 10_20241207_105417_0000.pdf",
        document_id: "BJvTuIIwetnHS5yWK6Rz",
      },
      {
        title: "lecture 12_20250102_130636_0000.pdf",
        document_id: "k0rN59f2PacgXSiiX2eK",
      },
      {
        title: "lecture 13_20250102_130716_0000.pdf",
        document_id: "uQFBoaDTxSyjlNeNHfvZ",
      },
      {
        title: "lecture 14 part-2_20250102_131515_0000.pdf",
        document_id: "92RycW8nQF7YCckScInq",
      },
      {
        title: "lecture 14_20250102_130937_0000.pdf",
        document_id: "VBcPWeZjL6tbxW0sTmjF",
      },
      {
        title: "lecture 18_20250102_131627_0000.pdf",
        document_id: "akNZkZ09bbUtAJTnxISm",
      },
      {
        title: "lecture 19_20250102_131642_0000.pdf",
        document_id: "QBEItyMdnucv4QrHAnIV",
      },
      {
        title: "lecture 20_20250102_131656_0000.pdf",
        document_id: "jP45zQriw7rLPoWlIxoX",
      },
      {
        title: "lecture 21_20250102_131750_0000.pdf",
        document_id: "1SYPM2cgSWBh11kwA7Dc",
      },
      {
        title: "lecture 22 part- 1_20250103_125959_0000.pdf",
        document_id: "Mra5NvTLYf60Popch3a1",
      },
      {
        title: "lecture 23_20250103_130155_0000.pdf",
        document_id: "Z7KyOQD0d5eOJlCFM7Lo",
      },
      {
        title: "lecture 28_20250103_130831_0000.pdf",
        document_id: "W8RAno1cRinG9Kd2oXxX",
      },
      {
        title: "voe academy-1.pdf",
        document_id: "4F5JFkdVN9hLWZzoX7XV",
      },
    ],
  },
  {
    folder_id: "fol_navo6and9",
    title: "Navodaya Vidyalaya class 6th and 9th",
    isSubFolderExist: false,
    documents: [
      {
        title: "FINAL_CLASS_IX_PROSPECTUS_2025.pdf",
        document_id: "ThUuOEteGjjWw1gtELNV",
      },
      {
        title: "NVS-Class-9th-Previous-Year-Question-Papers-2021.pdf",
        document_id: "io3uvSN0wpTwl5LrLlqB",
      },
      {
        title: "NVS-Class-9th-Previous-Year-Question-Papers-2022.pdf",
        document_id: "Lfvsne9pjhs5KmuYPhqo",
      },
      {
        title: "NVS-Class-9th-Previous-Year-Question-Papers-2023.pdf",
        document_id: "nvTutAJDe9rz5KqxuSlz",
      },
      {
        title:
          "Navodaya Vidyalaya Class 9 Question Paper in Hindi Medium for the Year 2019.pdf",
        document_id: "K9Fv06QGqG96kkSHqKWP",
      },
      {
        title: "Navodaya Vidyalaya-Class-IX-Previous-Year-Paper-2015.pdf",
        document_id: "4SqXI2x6kjzRRPKG0CZP",
      },
      {
        title: "Navodaya Vidyalaya-Class-IX-Previous-Year-Paper-2016.pdf",
        document_id: "oaYAxvlahionTUMwlV9k",
      },
      {
        title: "Navodaya vidyalaya class 6&9.pdf",
        document_id: "nN3k00486jLiQj2qkTRl",
      },
      {
        title: "Navodaya-Vidyalaya-Class-6-Question-Paper-2016.pdf",
        document_id: "6c9N2Wg0SMiTqzMH4kWt",
      },
      {
        title: "Navodaya-Vidyalaya-Class-6-Question-Paper-2017.pdf",
        document_id: "sRElt799tnNFsUdPpEoO",
      },
      {
        title: "Navodaya-Vidyalaya-Class-6-Question-Paper-2018.pdf",
        document_id: "O41vP3zUBYfVxGYK1Nek",
      },
      {
        title: "Navodaya-Vidyalaya-Class-6-Question-Paper-2019.pdf",
        document_id: "7vNoMDKcJfly57KEtNmy",
      },
      {
        title: "Navodaya-Vidyalaya-Class-6-Question-Paper-2020.pdf",
        document_id: "ChwgKDtufmLvIjdJqOio",
      },
      {
        title: "Navodaya-Vidyalaya-Class-6-Question-Paper-2021.pdf",
        document_id: "jCAQJOCIWUZCWasAlF5B",
      },
      {
        title: "Navodaya-Vidyalaya-Class-6-Question-Paper-2022.pdf",
        document_id: "pGUQV8tHgG1zsfwM7XBu",
      },
      {
        title: "Navodaya-Vidyalaya-Class-6-Question-Paper-2023.pdf",
        document_id: "OB3mIu4NAW9Yzwn9vEfB",
      },
      {
        title: "Navodaya-Vidyalaya-Class-6-Question-Paper-2024.pdf",
        document_id: "ICEdgzHArXOWsLepADFA",
      },
    ],
  },
  {
    folder_id: "fol_msc10",
    title: "Maths syllabus class 10th",
    isSubFolderExist: false,
    documents: [
      {
        title: "Haryana board syllabus.pdf",
        document_id: "PWbljaxZe8SimzIrv4ed",
      },
      {
        title: "ICSE-Class-10-Maths-Syllabus-2023-24.pdf",
        document_id: "nCvMkSAlE9o3Y8wrAOUD",
      },
      {
        title: "Maharashtra syllabus.pdf",
        document_id: "jG21pfGe9VTRmsOq3Vat",
      },
      {
        title: "Punjab Syllabus.pdf",
        document_id: "Fi5KKwgRzQhkXawYt6fP",
      },
      {
        title: "Up Math.pdf",
        document_id: "mfRaEBBqChIe8cK8XYPu",
      },
      {
        title: "maths cbse .pdf",
        document_id: "yIUQUMz6IYldKds9O95W",
      },
    ],
  },
  {
    folder_id: "fol_kays",
    title: "Know about your state",
    isSubFolderExist: false,
    documents: [
      {
        title: "Andhra Pradesh_.png",
        document_id: "SJh6XfYQ33pbkYwdTQyS",
      },
      {
        title: "Arunachal Pradesh_.png",
        document_id: "YY1ZM5o5xqeG2gqOIWeZ",
      },
      {
        title: "Assam_.png",
        document_id: "7NsXelAPBCTqdI639pXW",
      },
      {
        title: "Chattisgarh_.png",
        document_id: "1daPWf7qgUOBGAAJgkbB",
      },
      {
        title: "Goa.png",
        document_id: "x6Xu15qMvkhmxDV6NXnL",
      },
      {
        title: "Haryana_.png",
        document_id: "vOUVnO8Il8Ok32hsImh7",
      },
      {
        title: "Himachal Pradesh_.png",
        document_id: "fJFV2wF4cW5948RB7cGm",
      },
      {
        title: "Jharkhand_.png",
        document_id: "6axIDtpHOlzoWJr4BqNy",
      },
      {
        title: "Karnataka_.png",
        document_id: "gJ4h9sqLpYVvs29VphyR",
      },
      {
        title: "Kerala_.webp",
        document_id: "VR9dEP9Gv6mg2TkyPvEs",
      },
      {
        title: "Madhya Pradesh_.png",
        document_id: "XqJKgTbaOriVXYWHpNny",
      },
      {
        title: "Maharashtra_.png",
        document_id: "234XxeZbzTHSOovUum42",
      },
      {
        title: "Manipur_.png",
        document_id: "BOKIbEKrtivnyL8WP7uD",
      },
      {
        title: "Meghalaya_.png",
        document_id: "KdPnG9IA4XLxTg7GWKMx",
      },
      {
        title: "Mizoram_.png",
        document_id: "J1jLBnEIWfXVc7m4oeeG",
      },
      {
        title: "Odisha_.png",
        document_id: "xuvVZ1fuPy12RyoOGx86",
      },
      {
        title: "Punjab_.png",
        document_id: "fN0uV24gYbrgTUAdlv9n",
      },
      {
        title: "Rajasthan_.webp",
        document_id: "FZMW82CE8spMNvAfwc4M",
      },
      {
        title: "Sikkim_.png",
        document_id: "iV2wQSEBn0snR6iAxLqx",
      },
      {
        title: "Tamil Nadu_.png",
        document_id: "rnZnlzMnS1GWGpA4X5dl",
      },
      {
        title: "Telangana_.png",
        document_id: "OzPQVjZ47fy1rOTt7pJI",
      },
      {
        title: "Tripura_.png",
        document_id: "od5HOk6HxpJUHYJ4XGhQ",
      },
      {
        title: "Uttarakhand_.png",
        document_id: "v4x93RyribeGRsQs5I7W",
      },
      {
        title: "West Bengal_.png",
        document_id: "VX0ySzkCSRASD8GraBZc",
      },
    ],
  },
  {
    folder_id: "fol_pyq10cbse",
    title: "PYQ CBSE class 10",
    isSubFolderExist: true,
    subFolders: [
      {
        folder_id: "fol_cpyq18",
        title: "PYQ 2018",
        isSubFolderExist: false,
        documents: [
          {
            title: "X-ENGLISH_COMM_SQP_2019.pdf",
            document_id: "tDSGkfLSiVBhp5AtUmPr",
          },
          {
            title: "X-English_LANG_LIT_SQP_2019.pdf",
            document_id: "vtJec5tPLczBGx43uyBE",
          },
          {
            title: "X-Hindi-A-SQP_2018-19.pdf",
            document_id: "hzen0jbGI3uLkCHq5mDQ",
          },
          {
            title: "X-Hindi-B_SQP_2018-19.pdf",
            document_id: "uSNIZzulZ5SEEDbScOF5",
          },
          {
            title: "X-Maths_SQP_2018-19.pdf",
            document_id: "GZr9ie9ZP0eOuWm5yF9F",
          },
          {
            title: "X-SS_SQP_2018-19.pdf",
            document_id: "HYzahr931uF6EXfwYS9q",
          },
          {
            title: "X_Science_SQP_2018-19.pdf",
            document_id: "wM9lrGg8qB9chjuqcYL4",
          },
        ],
      },
      {
        folder_id: "fol_cpyq19",
        title: "PYQ 2019",
        isSubFolderExist: false,
        documents: [
          {
            title: "English-LL_SQP.pdf",
            document_id: "ur3jBpFYk5fHzBmtEa3I",
          },
          {
            title: "HindiA_SQP.pdf",
            document_id: "gpl9MLDDUifB8YemE85d",
          },
          {
            title: "HindiB_SQP.pdf",
            document_id: "HgK2Er6SZ6dWiEdGQbzJ",
          },
          {
            title: "Science_SQP (1).pdf",
            document_id: "jVfc0tK88cqzoWFX59Qt",
          },
          {
            title: "SocialScience_SQP (1).pdf",
            document_id: "BeugZLYnlSTRFRthBq4H",
          },
        ],
      },
      {
        folder_id: "fol_cpyq20",
        title: "PYQ 2020",
        isSubFolderExist: false,
        documents: [
          {
            title: "EnglishL-SQP.pdf",
            document_id: "cVZ38wSsD4bSecDThmqa",
          },
          {
            title: "HindiCourseA-SQP.pdf",
            document_id: "oQjEw2oI8oDfeB4YRXD2",
          },
          {
            title: "HindiCourseB-SQP.pdf",
            document_id: "5BpziVv2hagxcVDIMsz6",
          },
          {
            title: "Science-SQP.pdf",
            document_id: "oeKKvxmcdFleMux7H6Hd",
          },
          {
            title: "SocialScience-SQP.pdf",
            document_id: "eAEuiLhNViOuZ1eqW0Sb",
          },
        ],
      },
      {
        folder_id: "fol_cpyq21",
        title: "PYQ 2021",
        isSubFolderExist: false,
        documents: [
          {
            title: "EnglishL-SQP.pdf",
            document_id: "dPJCvPYmcAKLsD3H7iE6",
          },
          {
            title: "EnglishL-SQP_Term2.pdf",
            document_id: "55qhXKbYg2aQBEopsEmY",
          },
          {
            title: "HindiCourseA-SQP.pdf",
            document_id: "fOKdhWMUUYXxXJhXsunf",
          },
          {
            title: "HindiCourseA-SQP_Term2.pdf",
            document_id: "BWlt6boWUMjXJ6ltZjwG",
          },
          {
            title: "HindiCourseB-SQP.pdf",
            document_id: "MkEQAvx04TwIHuHjgDjM",
          },
          {
            title: "HindiCourseB-SQP_Term2.pdf",
            document_id: "yyZzHcWr9wBJYAeL8VBt",
          },
          {
            title: "Science-SQP.pdf",
            document_id: "pjhnR3unoiEb4Jv1gixb",
          },
          {
            title: "Science-SQP_Term2.pdf",
            document_id: "xMnSkKBFo4PWmKHLZrRy",
          },
          {
            title: "SocialScience-SQP.pdf",
            document_id: "KwMRl83oVvq4ayVjwowh",
          },
          {
            title: "SocialScience-SQP_Term2.pdf",
            document_id: "7Cr4sPSVP0G34tA3RTps",
          },
        ],
      },
      {
        folder_id: "fol_cpyq22",
        title: "PYQ 2022",
        isSubFolderExist: false,
        documents: [
          {
            title: "EnglishL-SQP.pdf",
            document_id: "2Rr2kEoFiYqS7gFJtjr8",
          },
          {
            title: "HindiCourseB-SQP.pdf",
            document_id: "Qmp6QlLcPvuyjmqdpd6m",
          },
          {
            title: "Science-SQP.pdf",
            document_id: "klVwcsuHLxzsfnO6uv1q",
          },
          {
            title: "SocialScience-SQP.pdf",
            document_id: "cXI23QJFxfiaAi8KAt33",
          },
        ],
      },
      {
        folder_id: "fol_cpyq23",
        title: "PYQ 2023",
        isSubFolderExist: false,
        documents: [
          {
            title: "EnglishComm-SQP.pdf",
            document_id: "7BTnkAh6x4pk43QNYPO4",
          },
          {
            title: "EnglishL-SQP.pdf",
            document_id: "0TFGUQLs5Pbmi7BLoAue",
          },
          {
            title: "HindiCourseA-SQP.pdf",
            document_id: "Xc05qOEOl0ICsohib8lJ",
          },
          {
            title: "HindiCourseB-SQP.pdf",
            document_id: "CQZYtGMlbNCRVD4nyS5y",
          },
          {
            title: "Science-SQP.pdf",
            document_id: "KDa1zy33bnElYGUngXT3",
          },
          {
            title: "SocialScience-SQP.pdf",
            document_id: "OwlF7adPLeD1xRHKC0IP",
          },
        ],
      },
      {
        folder_id: "fol_cpyq24",
        title: "PYQ 2024",
        isSubFolderExist: false,
        documents: [
          {
            title: "EnglishComm-SQP.pdf",
            document_id: "jenij0UCj1aiFhXf5Y6l",
          },
          {
            title: "EnglishL-SQP.pdf",
            document_id: "iZBKdhsgC3uidMnZ1BH7",
          },
          {
            title: "HindiCourseA-SQP.pdf",
            document_id: "StGrGa133zMxzS2pNYsw",
          },
          {
            title: "HindiCourseB-SQP.pdf",
            document_id: "Q4yS5gyZGG4m7tmCM3mc",
          },
          {
            title: "Science-SQP.pdf",
            document_id: "LWZagJT3UsZARjS9xvRV",
          },
          {
            title: "SocialScience-SQP.pdf",
            document_id: "n5vyIpuC2IpcNC0qtVu8",
          },
        ],
      },
      {
        folder_id: "fol_bmc10",
        title: "Basic Maths",
        isSubFolderExist: false,
        documents: [
          {
            title: "2019-2020 Basic maths.pdf",
            document_id: "CuoklFDi5C5wOugVJ5Eh",
          },
          {
            title: "2020-2021 basic math.pdf",
            document_id: "P7P7GFKJgldxHP7X1sap",
          },
          {
            title: "2021-2022 maths basic.pdf",
            document_id: "ir5JdEvjp7Brm6ZUNDvi",
          },
          {
            title: "2021-2022 term2 basic maths.pdf",
            document_id: "LjBWuWaVQH2H8fO7ymTS",
          },
          {
            title: "2022-23 basic maths.pdf",
            document_id: "kxCHNE4gX75D3gWgqbus",
          },
          {
            title: "2023-24 basic maths.pdf",
            document_id: "ijOQrCOyBBYnpJ0uBvgU",
          },
          {
            title: "2024-25 basic maths.pdf",
            document_id: "dmsH7LPf72MXcE6YihLI",
          },
        ],
      },
      {
        folder_id: "fol_smc10",
        title: "Standard Maths",
        isSubFolderExist: false,
        documents: [
          {
            title: "2019-2020maths standard.pdf",
            document_id: "nyberwEP3yiGit6z2huD",
          },
          {
            title: "2020-2021 basic Maths.pdf",
            document_id: "bnttYvw5rPU8SONBVNEu",
          },
          {
            title: "2021-2022 maths standard.pdf",
            document_id: "RWvMtK6iyN2zCVMhwvmM",
          },
          {
            title: "2021-2022 team 2 standard maths.pdf",
            document_id: "hzAhy7BdMJFdb2dDWPFF",
          },
          {
            title: "2022-23 standard maths.pdf",
            document_id: "CdBafVMadTB1hgPq51CC",
          },
          {
            title: "2023-24 standard maths.pdf",
            document_id: "SkADTt7GNqdis1xJEONf",
          },
          {
            title: "2024-25 standard maths.pdf",
            document_id: "PoCvo98A3qzv7l7f6Vpi",
          },
        ],
      },
    ],
  },

  {
    folder_id: "fol_pyq10",
    title: "PYQ class 10",
    isSubFolderExist: true,
    subFolders: [
      {
        folder_id: "fol_pyq10bihar",
        title: "PYQ Bihar board class 10",
        isSubFolderExist: false,
        documents: [
          {
            title: "bihar-board-class-10-st-mathematics-2017.pdf",
            document_id: "FNUNnh3fzTeT8rKyhFn9",
          },
          {
            title: "bihar-board-class-10-mathematics-110-set-j-2024.pdf",
            document_id: "DdraJT5FNGV1dcBGO3f8",
          },
          {
            title:
              "bihar-board-class-10-mathematics-supplementary-110-set-c-2024.pdf",
            document_id: "JPX2hYcwD2rmCzBzamIN",
          },
          {
            title: "bihar-board-class-10-mathematics-210-g-2020.pdf",
            document_id: "QkNX8frFNdo39pvddBdN",
          },
          {
            title: "bihar-board-class-10-mathematics-110-b-2019.pdf",
            document_id: "XRVFMO3bfU6xvvhtmV8c",
          },
          {
            title: "bihar-board-class-10-mathematics-c-210-2019-solutions.pdf",
            document_id: "c67L5s7udkLjQYNVKedO",
          },
          {
            title: "bihar-board-class-10-mathematics-210-set-e-2024.pdf",
            document_id: "fDVzwNdE3h62mxiRyEaC",
          },
          {
            title: "bihar-board-class-10-mathematics-813-2016.pdf",
            document_id: "lPqkvalvYyZrcl5S2TBz",
          },
          {
            title: "bihar-board-class-10-mathematics-110-set-1-2022.pdf",
            document_id: "p3AvddwvoOKwaJPZONoS",
          },
          {
            title: "bihar-board-class-10-mathematics-110-a-2022.pdf",
            document_id: "w42RLhwlp77Nii7qj5bN",
          },
          {
            title: "bihar-board-class-10-mathematics-compulsory-110-a-2021.pdf",
            document_id: "wyr1pjVBfjVfWoJxYkun",
          },
        ],
      },
      {
        folder_id: "fol_pyq10up",
        title: "PYQ UP board class 10",
        isSubFolderExist: false,
        documents: [
          {
            title: "up-board-class-10-hy-mathematics-7480-110724-2024.pdf",
            document_id: "DLFPLce14Ur6MRtVwmke",
          },
          {
            title: "up-board-class-10-mathematics-822-av-2019.pdf",
            document_id: "d8mtY4mwj12os4ab994N",
          },
          {
            title: "up-board-class-10-mathematics-822-av-2022.pdf",
            document_id: "NbvvBM4YYroqdpHjxIjC",
          },
          {
            title: "up-board-class-10-mathematics-822-aw-2017.pdf",
            document_id: "nFsNYjE7xlXAdVcwMVON",
          },
          {
            title: "up-board-class-10-mathematics-822-aw-2019.pdf",
            document_id: "6ee2Yv4KhyAEoWk0H38B",
          },
          {
            title: "up-board-class-10-mathematics-822-ax-2019.pdf",
            document_id: "F341Pd8CzHAQjXLdU4hX",
          },
          {
            title: "up-board-class-10-mathematics-822-ax-2022.pdf",
            document_id: "FPxjNYdqSnCFaVtUrcws",
          },
          {
            title: "up-board-class-10-mathematics-822-ay-2022.pdf",
            document_id: "oTqFy3qNi91n6CpP95VV",
          },
          {
            title: "up-board-class-10-mathematics-822-az-2019.pdf",
            document_id: "QVK7eNZnpBMsXRZekTFX",
          },
          {
            title: "up-board-class-10-mathematics-822-az-2022.pdf",
            document_id: "1RfZllSPlsfTGG2rFiwX",
          },
          {
            title: "up-board-class-10-mathematics-822-ba-2019.pdf",
            document_id: "5J51CBQxdccO6N7DgxXn",
          },
          {
            title: "up-board-class-10-mathematics-822-bb-2017.pdf",
            document_id: "d26o9nOiv3Q7wSl8kTPK",
          },
          {
            title: "up-board-class-10-mathematics-822-bb-2019.pdf",
            document_id: "LJzattdZuqzxJ6sTiKSC",
          },
          {
            title: "up-board-class-10-mathematics-822-cv-2016.pdf",
            document_id: "7wSwze3CK45UagqcQ2kL",
          },
          {
            title: "up-board-class-10-mathematics-822-cz-2016.pdf",
            document_id: "Fkj8gNGwbe62h4YxDSKK",
          },
          {
            title: "up-board-class-10-mathematics-822-dv-2023.pdf",
            document_id: "fZ8AMkP6Iz1T6XEMJ040",
          },
          {
            title: "up-board-class-10-mathematics-822-dw-2023.pdf",
            document_id: "XOkytYvvablgcncrNpeG",
          },
          {
            title: "up-board-class-10-mathematics-822-dx-2023.pdf",
            document_id: "mE3EC0Kypy4JoCy5X9K5",
          },
          {
            title: "up-board-class-10-mathematics-822-dy-2023.pdf",
            document_id: "xsEskMfzITVAKTCCxZlI",
          },
          {
            title: "up-board-class-10-mathematics-822-ea-2023.pdf",
            document_id: "IPkV6dJrAB6SMEjRo602",
          },
          {
            title: "up-board-class-10-mathematics-822-hi-2022.pdf",
            document_id: "cDU7o5f8oioVyCrqR3mJ",
          },
          {
            title: "up-board-class-10-mathematics-822-hy-2019.pdf",
            document_id: "R4YNRuXDdWYSXWN9Nm0D",
          },
          {
            title: "up-board-class-10-mathematics-822-hz-2018.pdf",
            document_id: "wAb0XifNkXe1eVTpfv0C",
          },
          {
            title: "up-board-class-10-mathematics-822-ia-2018.pdf",
            document_id: "a8Wam5iGIFhEEIVI6Cz9",
          },
          {
            title: "up-board-class-10-mathematics-822-mv-2020.pdf",
            document_id: "DGU3KUFg1nso6p5eLHG9",
          },
          {
            title: "up-board-class-10-mathematics-822-mw-2020.pdf",
            document_id: "4WU8UEbFjFAsjvoWG8oD",
          },
          {
            title: "up-board-class-10-mathematics-822-my-2020.pdf",
            document_id: "GEMmHtO6TW7vohLWyB9n",
          },
          {
            title: "up-board-class-10-mathematics-822-mz-2020.pdf",
            document_id: "7JopakkmEgIUiYGK4guo",
          },
          {
            title: "up-board-class-10-mathematics-822-na-2020.pdf",
            document_id: "TGIOGA7vArMAxGmfCGLo",
          },
          {
            title: "up-board-class-10-mathematics-822-nb-2020.pdf",
            document_id: "6o1xItxh81m4lJQMwVH7",
          },
          {
            title: "up-board-class-10-mathematics-822-re-2022.pdf",
            document_id: "VFJQElg1yuGjobrKAcOO",
          },
        ],
      },
      {
        folder_id: "fol_pyq10uk",
        title: "PYQ Uttarakhand board class 10",
        isSubFolderExist: false,
        documents: [
          {
            title: "class-10-hy-mathematics-160323-2023.pdf",
            document_id: "xlwPyalXKqvLOlLpvse5",
          },
          {
            title: "class-10-hy-science-160323-2023.pdf",
            document_id: "wsL6Nrn843ANdZcTnWGC",
          },
          {
            title: "class-10-mathematics-231-hgg-2023.pdf",
            document_id: "shxIiquBPCSzVcqKfMJM",
          },
          {
            title: "class-10-mathematics-231-hgh-2024.pdf",
            document_id: "xu2fdYbUzqc42yCdU2gk",
          },
          {
            title: "class-10-mathematics-231-hog-2022.pdf",
            document_id: "GRvpTFbtWHZCiXseg8db",
          },
          {
            title: "class-10-mathematics-231-hsg-2020.pdf",
            document_id: "6sjFp9VAVMSdD7SVZjta",
          },
          {
            title: "class-10-mathematics-231-hug-2019.pdf",
            document_id: "TYqmxBKiUxVGl79jbemx",
          },
          {
            title: "class-10-science-233-hgi-2023.pdf",
            document_id: "y1WzfxtJOtthNpUP5l9W",
          },
          {
            title: "class-10-science-233-hij-2024.pdf",
            document_id: "3RJTHOUNouS9i37US15D",
          },
          {
            title: "class-10-science-theory-233-hoi-2022.pdf",
            document_id: "vEkuosIw8okWj7n0Dddq",
          },
          {
            title: "class-10-science-theory-233-hsi-2020.pdf",
            document_id: "MoHsXZvdG5sk8OcIj6GU",
          },
          {
            title: "class-10-science-theory-233-hui-2019.pdf",
            document_id: "YJbjJIyTyyEh9POrzRx4",
          },
        ],
      },
      {
        folder_id: "fol_pyq10pb",
        title: "PYQ Punjab board class 10",
        isSubFolderExist: false,
        documents: [
          {
            title:
              "pseb-class-10-mathematics-question-paper-2016-paper-a-with-solutions.pdf",
            document_id: "7KrPsqkIEOW4lXpfNiHS",
          },
          {
            title:
              "pseb-class-10-mathematics-question-paper-2017-paper-a-with-solutions.pdf",
            document_id: "sgcw7NJUlvot8rm2TD8y",
          },
          {
            title:
              "pseb-class-10-mathematics-question-paper-2018-paper-c-with-solutions.pdf",
            document_id: "T8Q6pQc6uY3G028DWhtU",
          },
          {
            title:
              "pseb-class-10-mathematics-question-paper-2019-paper-with-solutions.pdf",
            document_id: "N8vuRHMwVlVvKUNylUJc",
          },
          {
            title: "selfstudys_com_file (1).pdf",
            document_id: "xTRNLNZnAMQgpkhA8Fek",
          },
          {
            title: "selfstudys_com_file.pdf",
            document_id: "Pzyyg0Nt3uOMRX5scrlb",
          },
        ],
      },
      {
        folder_id: "fol_pyq10mh",
        title: "PYQ Maharashtra board class 10",
        isSubFolderExist: false,
        documents: [
          {
            title:
              "mh-board-class-10-crop-science-paper-1-r-344-set-e-2021.pdf",
            document_id: "mlPBUIRgve47qyeZI5uU",
          },
          {
            title:
              "mh-board-class-10-crop-science-paper-1-rm-460-set-e-2021.pdf",
            document_id: "BxGKhU3H4A5UUscaoLPr",
          },
          {
            title: "mh-board-class-10-mathematics-71-n-710-2022.pdf",
            document_id: "xDXYJge0JoVR9njzNWN3",
          },
          {
            title: "mh-board-class-10-mathematics-algebra-71-n-619-2024.pdf",
            document_id: "YZmFQG85NMl9Yfl8fDFU",
          },
          {
            title: "mh-board-class-10-mathematics-algebra-71-n-620-2024.pdf",
            document_id: "o2lHLtO7AZLqOzzrtwtV",
          },
          {
            title: "mh-board-class-10-mathematics-algebra-71-n-711-2022.pdf",
            document_id: "wLCuMaaQd3eBryr6BkNc",
          },
          {
            title: "mh-board-class-10-mathematics-algebra-71-n-914-2023.pdf",
            document_id: "oEm5gLEDUpCZWxlfpbdL",
          },
          {
            title:
              "mh-board-class-10-mathematics-algebra-part-1-n-362-2020.pdf",
            document_id: "DJ58bzcjxkgKE8RVs6r2",
          },
          {
            title: "mh-board-class-10-mathematics-geometry-71-n-632-2024.pdf",
            document_id: "WvrOdBGVaa37UE2DQcal",
          },
          {
            title: "mh-board-class-10-mathematics-geometry-71-n-633-2024.pdf",
            document_id: "sQaXQWKfDEyVSKmx0zWP",
          },
          {
            title: "mh-board-class-10-mathematics-geometry-71-n-724-2022.pdf",
            document_id: "ARsgHgD3i6dqaIAJBiw5",
          },
          {
            title: "mh-board-class-10-mathematics-geometry-n-375-2020.pdf",
            document_id: "DXdZ2NTFyXsM2usFWyrW",
          },
          {
            title: "mh-board-class-10-mathematics-geometry-n-723-2022.pdf",
            document_id: "eK8CLM4aogvrM1rxbGNv",
          },
          {
            title: "mh-board-class-10-science-and-technology-72-n-640-2024.pdf",
            document_id: "BxpTL3eKB071v4AQUVTI",
          },
          {
            title: "mh-board-class-10-science-and-technology-72-n-654-2024.pdf",
            document_id: "vyXvCI0UuR0wmpKVoWr6",
          },
          {
            title: "mh-board-class-10-science-and-technology-72-n-732-2022.pdf",
            document_id: "pc7QP3I4JuIHCrgdWVsC",
          },
          {
            title: "mh-board-class-10-science-and-technology-72-n-745-2022.pdf",
            document_id: "Two7hGyQxvEIJYEAIjOk",
          },
          {
            title: "mh-board-class-10-science-and-technology-72-n-935-2023.pdf",
            document_id: "LXlHGMmlzeNZmDJVJadg",
          },
          {
            title: "mh-board-class-10-science-and-technology-72-n-948-2023.pdf",
            document_id: "yOQIXVlgpnlu9o7sGqFN",
          },
          {
            title: "mh-board-class-10-science-and-technology-n-383-2020.pdf",
            document_id: "uKmpoNkF6thdB1pFlYu8",
          },
          {
            title: "mh-board-class-10-science-and-technology-n-396-2020.pdf",
            document_id: "rrYgD0I6AVWKYzCmaTuX",
          },
          {
            title:
              "mh-board-class-10-science-and-technology-part-1-n-731-2022.pdf",
            document_id: "rkYVfU8OvJfhoe2cl6Da",
          },
          {
            title:
              "mh-board-class-10-science-and-technology-part-2-n-744-2022.pdf",
            document_id: "dwSLPgOTxlcAycJBrVBe",
          },
        ],
      },
      {
        folder_id: "fol_pyq10hr",
        title: "PYQ Haryana board class 10",
        isSubFolderExist: false,
        documents: [
          {
            title: "class-10-science-105-set-c-2023.pdf",
            document_id: "0p59UUikOzL74qZbl0Zu",
          },
          {
            title: "class-10-mathematics-103-set-c-2023.pdf",
            document_id: "1xQYLJjCVXsQLZFUj6X2",
          },
          {
            title: "class-10-mathematics-4803-b-2020.pdf",
            document_id: "5dG8tq7fW0G7sWArjFKO",
          },
          {
            title: "class-10-science-part-1-5505-set-d-apr-2021.pdf",
            document_id: "85zYnbTG3xs9HboiYRlV",
          },
          {
            title: "class-10-mathematics-4803-a-2020.pdf",
            document_id: "9ms0KlfoL20bAePpSXRZ",
          },
          {
            title: "class-10-mathematics-103-apr-2021.pdf",
            document_id: "9yuY0z46UB8fd39RdKKr",
          },
          {
            title: "class-10-science-part-1-5505-set-c-apr-2021.pdf",
            document_id: "AJepe0jZEL7avfNORljm	",
          },
          {
            title: "class-10-science-105-set-b-2023.pdf",
            document_id: "Bc6N8vSMmJvTVmJYaieL",
          },
          {
            title: "class-10-science-106-2023.pdf",

            document_id: "IrZ7IXsD4yjzMJZwqLmr",
          },
          {
            title: "class-10-mathematics-basic-1104-set-c-2024.pdf",

            document_id: "J5uFWpfwhqgsIbBwkRaL",
          },
          {
            title: "class-10-mathematics-5503-2022.pdf",
            document_id: "MDIFjACoUq9afET3kBeK",
          },
          {
            title: "class-10-mathematics-103-set-a-2023.pdf",
            document_id: "PpP5xB9WAeET7p2uDZtC",
          },
          {
            title: "class-10-science-part-1-5505-set-a-apr-2021.pdf",
            document_id: "QfAarz7m8w7czJxeAcQM",
          },
          {
            title: "class-10-mathematics-part-1-5503-set-c-apr-2021.pdf",
            document_id: "RMOlo1gkENgjLwkWGg4g",
          },
          {
            title: "class-10-science-105-set-d-2023.pdf",
            document_id: "S1BLTqhzYc6xPKIEpqQn",
          },
          {
            title: "class-10-mathematics-104-2023(1).pdf",
            document_id: "SrgPfVFwHDrhF3UiqEvc",
          },
          {
            title: "class-10-mathematics-part-1-5503-set-d-apr-2021.pdf",
            document_id: "T63peXDoKnqZB3QvWhnI",
          },
          {
            title: "class-10-mathematics-4803-c-2020.pdf",
            document_id: "TixNrkaz2Dfh0nr2Lelt",
          },
          {
            title: "class-10-mathematics-104-2023.pdf",
            document_id: "Uelc8KQown8UNVhhqfHD",
          },
          {
            title: "class-10-mathematics-part-1-5503-set-a-apr-2021.pdf",
            document_id: "YWDimFKG7kfDok5AcSbT",
          },
          {
            title: "class-10-science-part-1-5505-set-b-apr-2021.pdf",
            document_id: "euhDxIGE30GDigLAfLI6",
          },
          {
            title: "class-10-mathematics-103-set-b-2023.pdf",
            document_id: "gXPS4oRbgHdyUZBuXl2z",
          },
          {
            title: "class-10-mathematics-103-set-d-2023.pdf",
            document_id: "gcwbR529ckNjppAirLTz",
          },
          {
            title: "class-10-science-1106-set-a-2024.pdf",
            document_id: "ipA8P49Vs2Sa8H80eZq3",
          },
          {
            title: "class-10-science-105-set-a-2023.pdf",
            document_id: "pV3VQE2cDNmWfAyjPqTv	",
          },
          {
            title: "class-10-mathematics-part-1-5503-set-b-apr-2021.pdf",
            document_id: "wFrctPGCJ0BXHt43GrmV	",
          },
        ],
      },
    ],
  },
];

const StudyMaterial = () => {
  const [signinbool, setsigninbool] = useState(false);
  const [showSignup, setShowSignup] = useState(false);
  const isSignedIn = async () => {
    let headers = new Headers();
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    headers.append("Content-Type", "application/json");

    headers.append("Accept", "application/json");
    // REQUEST SIGNUP

    await fetch(process.env.REACT_APP_BACKEND_URL + "/issignedin", {
      method: "get",
      headers: headers,
      mode: "cors",
      credentials: "include",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setsigninbool(data.signinbool);
      });
  };
  const handleToggleStudyMaterialSubTab = (subtab_id) => {
    const place = document.getElementById(subtab_id);

    if (place.style.display === "block") {
      place.style.display = "none";
    } else {
      place.style.display = "block";
    }
  };
  const handleToggleStudyMaterialData = (documents) => {
    documents.documents.map((subtab_id) => {
      const place = document.getElementById(subtab_id.document_id);
      if (place.style.display === "flex") {
        place.style.display = "none";
      } else {
        place.style.display = "flex";
      }
    });
  };
  useEffect(() => {
    isSignedIn();
  }, []);

  const downloadDocument = async (document_id) => {
    try {
      if (!signinbool) {
        setShowSignup(true);
      } else {
        let headers = new Headers();
        headers.append("Access-Control-Allow-Origin", "*");
        headers.append("Access-Control-Allow-Credentials", "true");
        headers.append("Content-Type", "application/json");

        headers.append("Accept", "application/json");
        // REQUEST SIGNUP

        await fetch(
          process.env.REACT_APP_BACKEND_URL +
            `/get-study-material-doc/${document_id}`,
          {
            method: "get",
            headers: headers,
            mode: "cors",
            credentials: "include",
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            const a = document.createElement("a");

            a.href = data.data.url;
            a.download = data.data.filename;

            // Append the anchor to the body
            document.body.appendChild(a);

            // Trigger the click event
            a.click();

            // Remove the anchor from the body
            document.body.removeChild(a);
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {!showSignup ? (
        <div id="StudyMaterialBox">
          <h1>Study material</h1>
          <div className="StudyMaterialTabs">
            <ul>
              {study_material_document.map((study_material, idx) => {
                return (
                  <>
                    {study_material.isSubFolderExist ? (
                      <>
                        <li
                          className="mainStudyMaterialTab"
                          onClick={() =>
                            handleToggleStudyMaterialSubTab("subtab" + idx)
                          }
                        >
                          <div className="ArrowDownMoreStudyMaterial"></div>
                          {study_material.title}
                        </li>
                        <ul className="StudyMaterialSubTab" id={"subtab" + idx}>
                          {study_material.subFolders.map(
                            (subfolders, idx_sub) => {
                              return (
                                <>
                                  <li
                                    className="StudyMaterialColumn"
                                    onClick={() =>
                                      handleToggleStudyMaterialData(subfolders)
                                    }
                                  >
                                    <div className="ArrowDownMoreStudyMaterial"></div>
                                    {subfolders.title}
                                  </li>
                                  <ul>
                                    {subfolders.documents.map(
                                      (document, doc_id) => {
                                        return (
                                          <li
                                            id={document.document_id}
                                            className="StudyMaterialData"
                                          >
                                            <p>{document.title}</p>
                                            <div className="StudyMaterialDataOptions">
                                              <a
                                                href={`/studymaterial/document/${document.document_id}`}
                                              >
                                                Open
                                              </a>
                                              <svg
                                                onClick={() =>
                                                  downloadDocument(
                                                    document.document_id
                                                  )
                                                }
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <g
                                                  id="SVGRepo_bgCarrier"
                                                  stroke-width="0"
                                                ></g>
                                                <g
                                                  id="SVGRepo_tracerCarrier"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                ></g>
                                                <g id="SVGRepo_iconCarrier">
                                                  {" "}
                                                  <path
                                                    d="M3 15C3 17.8284 3 19.2426 3.87868 20.1213C4.75736 21 6.17157 21 9 21H15C17.8284 21 19.2426 21 20.1213 20.1213C21 19.2426 21 17.8284 21 15"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                  ></path>{" "}
                                                  <path
                                                    d="M12 3V16M12 16L16 11.625M12 16L8 11.625"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                  ></path>{" "}
                                                </g>
                                              </svg>
                                            </div>
                                          </li>
                                        );
                                      }
                                    )}
                                  </ul>
                                </>
                              );
                            }
                          )}
                        </ul>
                      </>
                    ) : (
                      <>
                        <li
                          className="mainStudyMaterialTab"
                          onClick={() => {
                            console.log(study_material);
                            handleToggleStudyMaterialData(study_material);
                          }}
                        >
                          <div className="ArrowDownMoreStudyMaterial"></div>
                          {study_material.title}
                        </li>
                        <ul>
                          {study_material.documents.map((document, doc_id) => {
                            return (
                              <div>
                                <li
                                  className="StudyMaterialData"
                                  id={document.document_id}
                                >
                                  <p>{document.title}</p>
                                  <div className="StudyMaterialDataOptions">
                                    <a
                                      href={`/studymaterial/document/${document.document_id}`}
                                    >
                                      Open
                                    </a>
                                    <svg
                                      onClick={() =>
                                        downloadDocument(document.document_id)
                                      }
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g
                                        id="SVGRepo_bgCarrier"
                                        stroke-width="0"
                                      ></g>
                                      <g
                                        id="SVGRepo_tracerCarrier"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></g>
                                      <g id="SVGRepo_iconCarrier">
                                        {" "}
                                        <path
                                          d="M3 15C3 17.8284 3 19.2426 3.87868 20.1213C4.75736 21 6.17157 21 9 21H15C17.8284 21 19.2426 21 20.1213 20.1213C21 19.2426 21 17.8284 21 15"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        ></path>{" "}
                                        <path
                                          d="M12 3V16M12 16L16 11.625M12 16L8 11.625"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        ></path>{" "}
                                      </g>
                                    </svg>
                                  </div>
                                </li>
                              </div>
                            );
                          })}
                        </ul>
                      </>
                    )}
                  </>
                );
              })}
            </ul>
          </div>{" "}
        </div>
      ) : (
        <div>
          <Signup />
        </div>
      )}
    </div>
  );
};

export default StudyMaterial;
